<template>
	<div class="ecposition-center-info">
		<div class="humanrobat">
			<img class="robat-img" :src="robatURL" alt="">
			<img class="robat-bg " src="~@/assets/images/exposition/humanrobat-bg.png" alt="">
			<div v-if="robatMP3">
				<!-- <audio autoplay :src="robatMP3" id="humanRobatAudio" /> -->
			</div>
			<div v-if="textPopShow" class="text-pop">
				<span class="pop-close" @click="textPopShow = false">+</span>
				<div class="pop-box">
					<div :style="{'top': textTop + 'px'}">
						<p>铁塔移动能源数字生态平台集“云－管－端”
							一体，将光伏、储能、充电、换电、电动汽车、边缘算力高效融合，促进绿色转型，为零碳交通体系及电动城市提供智慧能源服务。场站聚焦源网荷储一体化、智慧运营、智简运维、智能服务核心价值，打造以零碳为目标的下一代能源补给中心。光储协同深度融合，提升光伏消纳率，本地充换电优先消纳，收益约为并网的2倍。储能系统平谷充电，尖峰放电，实现场站低成本用电，灵活调配电力资源，最大提升经济效益。V2G双向互动，发挥电动汽车分布式能源优势，车主通过峰谷差收益，同时可参与调峰、调频等虚拟电厂辅助服务。
						</p>
						<p>数字平台基于能源AI、电池AI、场站模型、预测算法等技术，实现资源利用和效益最大化。智能分时充电策略充分利用低价电，节省电费高达38%；
							车辆智能调度精准预测需求量，提升运营效率约30%；智能分流策略可优化充换电服务效率和用户体验，同时平衡用电负荷，让车辆参与电网调节；智简运维基于实时监控、自动巡检、故障预测、
							RPA任务机器人、服务机器人等技术，综合减少人力成本高达50%。虚拟电厂通过数字化技术调用可调节负荷、分布式电源、储能等资源，形成对电力平衡的有效调节，需求侧响应、电能交易以及辅助服务带来收益。
						</p>
					</div>
				</div>
			</div>
			<div v-if="pptPopShow" class="ppt-pop">
				<div class="pop-box">
					<el-carousel height="264px" :interval="10000">
						<el-carousel-item v-for="(item, i) in robatPPT" :key="'robatPPT' + i">
							<img class="ppt-img" :src="item.url" alt="" />
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<target></target>
		<!-- 进电站的小车 -->
		<img class="smallcar" v-if="chenyongchehuandianData.status == '换电中'"
			src="~@/assets/images/exposition/carsmall.png" alt="">
		<img class="smallcarout smallcarout-an" v-if="chengyongcheOut" src="~@/assets/images/exposition/car-out.png"
			alt="">

		<img class="robot-car" :class="{'robotCarRun-an': robotAnimation}" src="~@/assets/images/exposition/car-1.png"
			alt="" />
		<!-- 光伏板 -->
		<img class="gfb-bg" src="~@/assets/images/exposition/gfb-bg.png" alt="">

		<!-- 充电桩车辆 -->
		<img class="smallcar2 smallcarmove2" v-if="display_state == '充电中' || display_state == '放电中'"
			src="~@/assets/images/exposition/carsmall.png" alt="">
		<img class="smallcar2 smallcarmoveout" v-if="display_state == '空闲'" src="~@/assets/images/exposition/car-1.png"
			alt="">
		<!-- <div class="car-box">
			<img class="smallcarcell" v-show="item.charge_state == '充电中'" v-for="(item, index) in pileList"
				:key="'car'+ index" src="~@/assets/images/exposition/carsmall1.png" alt="">
		</div> -->
		<!-- <div class="car-box">
			<img class="smallcarcell" v-for="(item, index) in pileList"
				:key="'car'+ index" src="~@/assets/images/exposition/carsmall1.png" alt="">
		</div> -->

		<!-- 商用车车辆 -->
		<img class="smallcar3" v-if="shangyongchehuandianData.status == '换电中'"
			src="~@/assets/images/exposition/bigcar.png" alt="">
		<img class="smallcar3out smallcar3outmove" v-if="shangyongcheOut"
			src="~@/assets/images/exposition/bigcar-out.png" alt="">

		<img class="guangdiandongxiaoa1" v-if="guangfucahngjingjiaohuData.status == '发电中'"
			src="~@/assets/images/exposition/guangdiandongxiaoA.png" alt="">
		<img class="guangdiandongxiaoa2" v-if="guangfucahngjingjiaohuData.status == '发电中'"
			src="~@/assets/images/exposition/guangdiandongxiaoA.png" alt="">
		<img class="guangdiandongxiaoa3" v-if="guangfucahngjingjiaohuData.status == '发电中'"
			src="~@/assets/images/exposition/guangdiandongxiaoA.png" alt="">
		<img class="guangdiandongxiaoa7" v-if="guangfucahngjingjiaohuData.status == '发电中'"
			src="~@/assets/images/exposition/guangdiandongxiaoA.png" alt="">

		<img class="guangdiandongxiaoa4" v-if="chunengchangjingData.status == '充电中'"
			src="~@/assets/images/exposition/guangdiandongxiaoB.png" alt="">
		<img class="guangdiandongxiaoa5" src="~@/assets/images/exposition/suanliyun.png" alt="">
		<!-- 储能放电动图 -->
		<img class="chunengdonogxiao" v-if="chunengchangjingData.status == '放电中'"
			src="~@/assets/images/exposition/chunengdongxiao.png" alt="">

		<img class="suanlichuanbo" src="~@/assets/images/exposition/suanlichuanbo.png" alt="">

		<!-- 文案标记 -->
		<div class="title-name">钙钛矿光伏</div>

		<!-- 文案标记 -->
		<div class="title-name title-namev2g">V2G</div>

		<!-- 充电机器人 -->
		<div class="robot-box" ref="positionrobot" :class="{'robotRun1-an': false}"> <!-- run -->
			<div class="title-name title-name1" v-if="!shownotice">充电机器人</div>
			<img class="robot-1" v-if="!showChargeImg" src="~@/assets/images/exposition/robot-1.png" />
			<img class="robot-1 robot-box111" v-else src="~@/assets/images/exposition/robot-box111.png" />
			<div :class="[chargeNotice ? 'notice' : 'noticeTo']" v-if="shownotice">
				<span v-if="chargeNotice">
					小鑫正在为您充电
				</span>
				<span v-else>
					充电结束，您的电池状态良好
				</span>
			</div>
		</div>
		<div class="robot2-box animation" :class="{'animation':shangyongchehuandianData.status !== '换电中'}">
			<div class="title-name title-name2" v-if="!shownotice">服务机器人</div>
			<img class="robot-2" src="~@/assets/images/exposition/robot-2.png" />
			<div class="notice" v-if="shownotice">我是小港，全程为您提供换电服务支持</div>
		</div>

		<div class="robot2-box10 animation" :class="{'animation':chenyongchehuandianData.status !== '换电中'}">
			<div class="title-name title-name2" v-if="!shownotice">服务机器人</div>
			<img class="robot-10" src="~@/assets/images/exposition/robot-10.png" />
			<div class="notice" v-if="shownotice">我是小港，全程为您提供换电服务支持</div>
		</div>

		<div class="huandian-hover" @click="showBox('1')"></div>
		<div class="guangfu-hover" @click="showBox('2')"></div>

		<!-- 充电桩点击列表 -->
		<div class="chongdianzhuang-hover">
			<div class="chongdianzhuang-hover-cell" @click="v2gClick">
				<img v-if="display_state == '充电中'" class="chart-img"
					src="~@/assets/images/exposition/guangdiandongxiaoA.png" alt="">
				<img v-if="display_state == '放电中'" class="chart-img" src="~@/assets/images/exposition/fangdian.png"
					alt="">
			</div>
			<div v-for="(item, index) in pileList" :key="'pileList' + index" class="chongdianzhuang-hover-cell"
				@click="show3Click(index, item)">
				<img v-if="item.charge_state == '充电中'" class="chart-img"
					src="~@/assets/images/exposition/guangdiandongxiaoA.png" alt="">
			</div>
		</div>


		<div class="chuneng-hover" @click="showBox('4')"></div>
		<div class="yunpingtai-hover" @click="showBox('5')"></div>
		<div class="huandian-shangyong-hover" @click="showBox('8')"></div>
		<div class="zhisuan-hover" @click="showBox('9')"></div>


		<!-- 云平台左边盒子 -->
		<!-- <img v-if="show5" class="yunpingtaiyinxian" src="~@/assets/images/exposition/yunpingtaiyinxian.png" alt=""> -->
		<!-- <div class="yun-left-box" v-if="show5">
			<div class="yun-left-box-cell" :class="{'yun-left-box-cell-active':echartIndex === index}"
				v-for="(item, index) in yunList" :key="index" @click="changeEcharts(index, item.subName)">{{item.name}}</div>
		</div> -->
		<!-- 云平台弹窗 -->
		<!-- <div class="yun-box2" v-if="show6">
			<div class="title">
				{{echartTitle}}
				<div class="act-name1  act-name" :class="{'yun-left-box-cell-active1':echartIndex1 === 0}"
					v-if="echartIndex === 0 || echartIndex === 1 || echartIndex === 2" @click="echartIndex1 = 0">
					乘用车
				</div>
				<div class="act-name2 act-name" :class="{'yun-left-box-cell-active1':echartIndex1 === 1}"
					v-if="echartIndex === 0 || echartIndex === 1 || echartIndex === 2" @click="echartIndex1 = 1">
					商用车
				</div>
			</div>
			<div class="chart" v-if="show6">
				<zhinengfenshichongdian v-if="echartIndex === 0 &&  echartIndex1 === 0"></zhinengfenshichongdian>
				<zhinengfenshichongdian1 v-if="echartIndex === 0 && echartIndex1 === 1"></zhinengfenshichongdian1>
				<cheliangzhinengdiaodu v-if="echartIndex === 1 &&  echartIndex1 === 0"></cheliangzhinengdiaodu>
				<cheliangzhinengdiaodu1 v-if="echartIndex === 1 &&  echartIndex1 === 1"></cheliangzhinengdiaodu1>
				<zhinengfenliu v-if="echartIndex === 2 && echartIndex1 === 0"></zhinengfenliu>
				<zhinengfenliu1 v-if="echartIndex === 2 && echartIndex1 === 1"></zhinengfenliu1>
				<zhinengyunwei v-if="echartIndex === 3 && echartIndex1 === 0"></zhinengyunwei>
				<zhinengyunwei1 v-if="echartIndex === 3 && echartIndex1 === 1"></zhinengyunwei1>
				<zhinengdiaodu v-if="echartIndex === 4"></zhinengdiaodu>
			</div>
		</div> -->


		<!-- 乘用车换电站弹窗 -->
		<div class="chuneng-box huandian-box" v-if="show1 || inturnShow == 4" @click="show1=false;inturnShow=0">
			<div class="title111">
				乘用换电站
				<div class="act-name" v-if="chenyongchehuandianData.status">
					{{ chenyongchehuandianData.status  }}
				</div>
				<div v-else class="act-name">
					空闲
				</div>
			</div>
			<div class="info-list">
				<div class="info-list-cell">
					换下SOC: {{ chenyongchehuandianData.down_soc  }}
				</div>
				<div class="info-list-cell">
					换上SOC: {{ chenyongchehuandianData.up_soc  }}
				</div>
			</div>
			<div class="chart">
				<huandianzhanchart></huandianzhanchart>
			</div>
			<div class="bottom1">
				<div v-if="chenyongchehuandianData.status != '换电中'" class="button1" @click.stop="startChange">开始换电</div>
				<div v-if="chenyongchehuandianData.status == '换电中'" class="button1" @click.stop="startChange1">强制结束
				</div>
			</div>
			<img @click.stop="stopClick" class="lineimg1" src="~@/assets/images/exposition/lineimg.png" alt="">
		</div>

		<!-- 商用车换电站弹窗 -->
		<div class="chuneng-box huandian-box-shangyong" v-if="show8 || inturnShow == 5"
			@click="show8=false;inturnShow=0">
			<div class="title111">
				商用换电站
				<div class="act-name" v-if="shangyongchehuandianData.status">
					{{ shangyongchehuandianData.status  }}
				</div>
				<div v-else class="act-name">
					空闲
				</div>
			</div>
			<div class="info-list">
				<div class="info-list-cell">
					换下SOC: {{ shangyongchehuandianData.down_soc  }}
				</div>
				<div class="info-list-cell">
					换上SOC: {{ shangyongchehuandianData.up_soc  }}
				</div>
			</div>
			<div class="chart">
				<huandianzhanchart1></huandianzhanchart1>
			</div>
			<div class="bottom1">
				<div v-if="shangyongchehuandianData.status != '换电中'" class="button1" @click.stop="startChange">开始换电
				</div>
				<div v-if="shangyongchehuandianData.status == '换电中'" class="button1" @click.stop="startChange1">强制结束
				</div>
			</div>
			<img @click.stop="stopClick" class="lineimg2" src="~@/assets/images/exposition/lineimg.png" alt="">
		</div>



		<!-- 光伏弹窗 -->
		<div class="chuneng-box guangfu-box" v-if="show2 || inturnShow == 1" @click="show2=false;inturnShow=0">
			<div class="title111">
				光伏
				<div class="act-name" v-if="guangfucahngjingjiaohuData.status">
					{{ guangfucahngjingjiaohuData.status  }}
				</div>
				<div v-else class="act-name">
					空闲
				</div>
			</div>
			<div class="info-list">
				<div class="info-list-cell">
					当前功率(kW): {{ guangfucahngjingjiaohuData.output_power  }}
				</div>
				<div class="info-list-cell">
					发电效率(%): {{ guangfucahngjingjiaohuData.output_efficiency  }}
				</div>
			</div>
			<div class="chart">
				<guangfuchart></guangfuchart>
			</div>

			<img @click.stop="stopClick" class="lineimg4" src="~@/assets/images/exposition/lineimg4.png" alt="">
		</div>


		<!-- 充电桩弹窗 -->
		<div v-if="show3 || inturnShow == 3" class="chuneng-box chongdianzhuang-box"
			:style="{left: 680 + ((show3ClickIndex + 1) * 83) + 'px'}" @click="show3=false;inturnShow=0">
			<div class="title111" v-if="isv2g">
				充电桩
				<div class="act-name" v-if="display_state">
					{{ display_state }}
				</div>
				<div v-else class="act-name">
					空闲
				</div>
			</div>
			<div class="title111" v-else>
				充电桩
				<div class="act-name" v-if="chongdianzhuangData.charge_state">
					{{ chongdianzhuangData.charge_state }}
				</div>
				<div v-else class="act-name">
					空闲
				</div>
			</div>

			<div class="info-list" v-if="isv2g">
				<div class="info-list-cell">
					充电量(kWh): {{ charge_elect }}
				</div>
				<div class="info-list-cell">

				</div>
			</div>
			<div class="info-list" v-else>
				<div class="info-list-cell">
					充电量(kWh): {{ chongdianzhuangData.elect }}
				</div>
				<div class="info-list-cell">

				</div>
			</div>
			<div class="chart">
				<chongdianzhuangchart></chongdianzhuangchart>
			</div>
			<div class="bottom2" v-if="isv2g">
				<div class="button2" v-if="display_state == '充电中'" @click.stop="startV2g('结束充电')">结束充电</div>
				<div class="button2" v-else-if="display_state == '放电中'" @click.stop="startV2g('结束放电')">结束放电</div>
				<span v-else>
					<div class="button2" @click.stop="startV2g('开始充电')">开始充电</div>
					<div class="button2-right" @click.stop="startV2g('开始放电')">开始放电</div>
				</span>

			</div>
			<div class="bottom2" v-else>
				<div class="button2" v-if="chongdianzhuangData.charge_state == '空闲'" @click.stop="startCharge1">开始充电
				</div>
				<div class="button2-right" v-if="chongdianzhuangData.charge_state == '充电中'" @click.stop="startrelease1">
					停止充电</div>
			</div>
			<img @click.stop="stopClick" class="lineimg3" src="~@/assets/images/exposition/lineimg3.png" alt="">

		</div>


		<!-- 储能弹窗 -->
		<div class="chuneng-box" v-if="show4 || inturnShow == 2" @click="show4=false;inturnShow=0">
			<div class="title111">
				储能
				<div class="act-name" v-if="chunengchangjingData.status">
					{{ chunengchangjingData.status  }}
				</div>
				<div v-else class="act-name">
					空闲
				</div>
			</div>
			<div class="info-list">
				<div class="info-list-cell">
					SOC(%): {{ chunengchangjingData.soc  }}
				</div>
				<div class="info-list-cell">
					电池温度(°C): {{ chunengchangjingData.max_temp  }}
				</div>
			</div>
			<div class="info-list">
				<div class="info-list-cell">
					电压(V): {{ chunengchangjingData.max_volt  }}
				</div>
				<div class="info-list-cell">
					电流(A): {{ chunengchangjingData.curr  }}
				</div>
			</div>
			<div class="chart">
				<chunengchart></chunengchart>
			</div>
			<img class="chunengxian" src="~@/assets/images/exposition/chunengxian.png" alt="">
		</div>

		<!-- 算力统计 -->
		<div class="chuneng-box suanlitongji" v-if="show9 || inturnShow == 6" @click="show9=false;inturnShow=0">
			<div class="title111">
				算力统计
				<div class="suanliact-name1  act-name suanliact-name" @click.stop="suanliechartIndexClick('3')"
					:class="{'yun-left-box-cell-active1':suanliechartIndex == 3}">
					硬盘
				</div>
				<div class="suanliact-name2 act-name suanliact-name" @click.stop="suanliechartIndexClick('2')"
					:class="{'yun-left-box-cell-active1':suanliechartIndex == 2}">
					内存
				</div>
				<div class="suanliact-name3  act-name suanliact-name" @click.stop="suanliechartIndexClick('1')"
					:class="{'yun-left-box-cell-active1':suanliechartIndex == 1}">
					gpu
				</div>
				<div class="suanliact-name4 act-name suanliact-name" @click.stop="suanliechartIndexClick('0')"
					:class="{'yun-left-box-cell-active1':suanliechartIndex == 0}">
					cpu
				</div>
			</div>
			<div class="chart">
				<suanlitongjicpu v-if="suanliechartIndex == 0"></suanlitongjicpu>
				<suanlitongjigpu v-if="suanliechartIndex == 1"></suanlitongjigpu>
				<suanlitongjineicun v-if="suanliechartIndex == 2"></suanlitongjineicun>
				<suanlitongjiyingpan v-if="suanliechartIndex == 3"></suanlitongjiyingpan>
			</div>
		</div>


		<!-- 视频播放 -->
		<!-- 视频内容 -->
		<img class="shexiangtou" @click.stop="showshipin1 = !showshipin1"
			src="~@/assets/images/exposition/shexiangtou.png" alt="">
		<img class="shexiangtou1" @click.stop="showshipin2 = !showshipin2"
			src="~@/assets/images/exposition/shexiangtou.png" alt="">


		<div class="shiping"
			v-show="showshipin1 || ((monArr.length > 0 && inturnShow==4)  && chenyongchehuandianData.status == '换电中')">
			<div class="parent">
				<div class="parent-box" v-for="(item,index) in monArr" :key="index">
					<video :ref="`my-player${index}`" :playUrl="item.url" class="videoa video-js" muted></video>
				</div>
			</div>
		</div>

		<div class="shiping1"
			v-show="showshipin2 || ((monArr1.length > 0 && inturnShow==5) && shangyongchehuandianData.status == '换电中')">
			<div class="parent">
				<div class="parent-box" v-for="(item,index) in monArr1" :key="index">
					<video :ref="`my-player1${index}`" :playUrl="item.url" class="videoa video-js" muted></video>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import 'video.js/dist/video-js.css'
	// import { videoPlayer } from 'vue-video-player'
	// import 'videojs-flash'
	import {
		dataInterface
	} from '@/api/dataInterfaceApi'
	import target from '@/views/components/exposition/target.vue'
	import guangfuchart from '@/views/components/exposition/guangfuchart.vue'
	import huandianzhanchart from '@/views/components/exposition/huandianzhanchart.vue'
	import huandianzhanchart1 from '@/views/components/exposition/huandianzhanchart1.vue'
	import chongdianzhuangchart from '@/views/components/exposition/chongdianzhuangchart.vue'
	import chunengchart from '@/views/components/exposition/chunengchart.vue'
	// import zhinengfenshichongdian from '@/views/components/exposition/zhinengfenshichongdian.vue'
	// import zhinengfenshichongdian1 from '@/views/components/exposition/zhinengfenshichongdian1.vue'
	// import cheliangzhinengdiaodu from '@/views/components/exposition/cheliangzhinengdiaodu.vue'
	// import cheliangzhinengdiaodu1 from '@/views/components/exposition/cheliangzhinengdiaodu1.vue'
	// import zhinengfenliu from '@/views/components/exposition/zhinengfenliu.vue'
	// import zhinengfenliu1 from '@/views/components/exposition/zhinengfenliu1.vue'
	// import zhinengyunwei from '@/views/components/exposition/zhinengyunwei.vue'
	// import zhinengyunwei1 from '@/views/components/exposition/zhinengyunwei1.vue'
	// import zhinengdiaodu from '@/views/components/exposition/zhinengdiaodu.vue'
	import suanlitongjicpu from '@/views/components/exposition/suanlitongjicpu.vue'
	import suanlitongjigpu from '@/views/components/exposition/suanlitongjigpu.vue'
	import suanlitongjineicun from '@/views/components/exposition/suanlitongjineicun.vue'
	import suanlitongjiyingpan from '@/views/components/exposition/suanlitongjiyingpan.vue'
	import {
		Message
	} from 'element-ui'
	export default {
		components: {
			// videoPlayer,
			suanlitongjicpu,
			suanlitongjigpu,
			suanlitongjineicun,
			suanlitongjiyingpan,
			target,
			guangfuchart,
			huandianzhanchart,
			huandianzhanchart1,
			chongdianzhuangchart,
			chunengchart,
			// zhinengfenshichongdian,
			// zhinengfenshichongdian1,
			// cheliangzhinengdiaodu,
			// cheliangzhinengdiaodu1,
			// zhinengfenliu,
			// zhinengfenliu1,
			// zhinengyunwei,
			// zhinengyunwei1,
			// zhinengdiaodu
		},
		data() {
			return {
				playerOptions: {
					height: "300",
					width: "600",
					sources: [{
						type: "rtmp/mp4",
						src: "rtmp://liveplay.vaas.cn-hangzhou-1.ctyunxs.cn/vaas/live/3301000100004_33010610021322000005_live_1?auth_key=1687849169-23c53230166a4de8911d2289904d1f0b-30003-f1110ffda443cf5e6770f3c0704a8d1a",
					}, ],
					techOrder: ["flash"],
					autoplay: true,
					controls: true
				},
				noticeTimer: '',
				isv2g: false,
				startChargecar: true,
				chargeNotice: true,
				show1: false,
				show2: false,
				show3: false,
				show4: false,
				show5: true,
				show6: true,
				show7: false,
				show8: false,
				show9: false,
				inturnShow: 0,
				suanliechartIndex1: 0,
				guangfucahngjingjiaohuData: {},
				chongdianzhuangData: {},
				chunengchangjingData: {},
				chenyongchehuandianData: {},
				shangyongchehuandianData: {},
				display_state: '放电中',
				signalPileStatus: '',
				listQuery: {
					currentPage: 1,
					pageSize: 9,
					sort: 'desc'
				},
				monArr: [],
				yunList: [{
					name: '智能分时充电',
					subName: '充电电池分布对比'
				}, {
					name: '车辆智能调度',
					subName: '运输效率提高率'
				}, {
					name: '站点智能分流',
					subName: '站点排队优化'
				}, {
					name: '少人化运维',
					subName: '任务自动化处理率'
				}, {
					name: '虚拟电厂',
					subName: '虚拟电厂调度处理'
				}],
				echartIndex: 0,
				echartIndex1: 0, //显示商用车还是乘用车
				echartTitle: '充电电池分布对比',
				videoObj: {},
				videoObj1: {},
				shownotice: true,
				timer: '',
				suanliechartIndex: 0,
				pileList: [],
				monArr1: [],
				iscanChargeAndchangge: true,
				showshipin1: false,
				showshipin2: false,
				charge_elect: '',
				show3ClickIndex: 0,
				robotAnimation: false,
				timer1: false,
				chengyongcheOut: false,
				shangyongcheOut: false,
				showChargeImg: false,
				showChargeImgTime: 0,
				robatPPT: [{
						url: require('@/assets/images/exposition/ppt-1.png')
					},
					{
						url: require('@/assets/images/exposition/ppt-2.png')
					},
					{
						url: require('@/assets/images/exposition/ppt-3.png')
					},
					{
						url: require('@/assets/images/exposition/ppt-4.png')
					}
				],
				robatURL: require('@/assets/images/exposition/humanrobat.png'),
				robatMP3: null,
				pptPopShow: false,
				textPopShow: false,
				textTop: 0,
				textInterval: null,
			}
		},
		computed: {
			'newParams': function() {
				let num = this.show1 + this.show2 + this.show3 + this.show4 + this.show5 + this.show7 + this.show8 +
					this.show9
				return num
			}
		},
		watch: {
			shownotice: function(value) {
				console.log(11111)
				console.log(value,'hidsijodsdsjisdij')
				if (value) {
					setTimeout(() => {
						this.chargeNotice = false
					}, 10000)
				} else {
					setTimeout(() => {
						this.chargeNotice = true
					}, 3000)
				}

			},
			// 监听全部弹窗如果隐藏掉重新轮播
			newParams: function() {

				if (!this.show1 && !this.show2 && !this.show3 && !this.show4 && !this.show8 && !this.show9) {
					this.showandhide()
				}
			}
		},
		methods: {
			showVideo() {
				//       let myPlayer = this.$refs.videoPlayer.player;

				//       // 用于多个视频源直接切换，但是现在只有一个视频源，
				//       myPlayer.src("rtmp://58.200.131.2:1935/livetv/hunantv");
			},
			// 进制点击
			stopClick() {

			},
			// 判断是否显示充电桩里的小车
			generateClassName(item) {
				if (item.display_state == '充电中') {
					return true
				} else {
					return false
				}
			},
			//获取v2g充电量
			getV2gchongdianliang() {
				let params = {
					"enCode": "snec-mid-charge-state-elect",
					"@harbour_id": localStorage.getItem("stationSite_id") ||
						'2fa983b015f1434b99d08c5de9f289aa5b1b5100e6bc4560a3d07efb1bb3ce8b',
					"@display_state": localStorage.getItem("display_state")
				}
				dataInterface.dataFaceApi(params).then((res) => {
					if (res) {
						this.charge_elect = res[0].charge_elect
					}
				}).catch((err) => {
					console.log(err);
				});
			},
			// 点击算力统计按钮
			suanliechartIndexClick(index) {
				this.suanliechartIndex = index
			},
			// 开始充电v2g
			startV2g(status) {
				let params = {
					'enCode': 'snec-mid-charge-state-switch',
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0',
					'@charge_state': status
				}
				dataInterface.dataFaceApi(params).then((res) => {
						if (!res) return
						this.display_state = res[0].display_state
						localStorage.setItem("display_state", this.display_state)
						localStorage.setItem("isV2g", 'isV2g')
						this.getV2gchongdianliang()
						this.$bus.$emit('offCloseChongdianFunction')
					})
					.catch((err) => {
						console.log(err);
					})
			},
			// 停止充电
			startrelease1() {
				Message({
					message: '请拔枪后再停止充电',
					type: 'warning'
				})
			},
			// 开始充电
			startCharge1() {
				Message({
					message: '暂未插枪',
					type: 'warning'
				})
			},
			// 获取充电桩列表
			getPileList() {
				let params = {
					'enCode': 'snec-mid-charge-list-state',
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0'
				}
				dataInterface.dataFaceApi(params).then((res) => {

						if (!res) return
						let arr = []
						res.forEach((item, index) => {
							if (index < 6) {
								arr.push(item)
							}
						})

						this.pileList = arr
					})
					.catch((err) => {
						console.log(err);
					})
			},
			//开始换电
			startChange() {
				Message({
					message: '车辆暂未准备完成',
					type: 'warning'
				})
			},
			//结束换电
			startChange1() {
				Message({
					message: '暂时未能结束换电',
					type: 'warning'
				})
			},
			//点击v2g
			v2gClick() {
				this.show3ClickIndex = -1
				this.isv2g = true
				this.chongdianzhuangData = false
				localStorage.setItem("isV2g", 'isV2g')
				clearInterval(this.timer)
				this.timer = false
				this.show3 = true
				this.$bus.$emit('offCloseChongdianFunction')
			},
			//点击充电桩
			show3Click(index, item) {
				this.show3ClickIndex = index
				this.isv2g = false
				clearInterval(this.timer)
				this.timer = false
				this.show3 = true
				localStorage.setItem("isV2g", 'isnotV2g')
				localStorage.setItem("pile_id", item.pile_id)
				this.chongdianzhuangchangjing()
				this.$bus.$emit('offCloseChongdianFunction')

			},
			// 点击显示不同的弹窗
			showBox(value) {
				clearInterval(this.timer)
				this.timer = false
				this.inturnShow = -1
				switch (value) {
					case '1':
						this.show1 = !this.show1
						break
					case '2':
						this.show2 = !this.show2
						break
					case '3':
						this.show3 = !this.show3
						break
					case '4':
						this.show4 = !this.show4
						break
					case '5':
						if (!this.show5) {
							this.echartIndex = 0
						} else {
							this.show6 = false
						}
						this.show5 = !this.show5
						break
					case '8':
						this.show8 = !this.show8
						break
					case '9':
						this.show9 = !this.show9
						break
				}

			},
			//轮流显示不同组件 
			showandhide() {
				let num = 1
				clearInterval(this.timer)
				this.timer = setInterval(() => {
					if (num >= 11) {
						num = -1
					}
					this.inturnShow = num

					if (this.inturnShow == 3) {
						this.show3ClickIndex = -1
						this.isv2g = true
						this.chongdianzhuangData = false
						this.$bus.$emit('offCloseChongdianFunction')
					}

					num += 1

				}, 7000)
			},
			startInter() {
				this.timer1 = setInterval(() => {
					this.show5 = true
					this.show6 = true
					if (this.echartIndex >= 4) {
						this.echartIndex = 0
					} else {
						this.echartIndex = this.echartIndex + 1
					}

					if (this.yunList[this.echartIndex]) {
						this.echartTitle = this.yunList[this.echartIndex].name
					}


				}, 7600)
			},
			//初始化视频
			initVideo(stationId) {
				this.monArr = []
				this.videoObj = {}

				var params = {
					stationId: stationId,
					pageSize: this.listQuery.pageSize,
					currentPage: this.listQuery.currentPage
				}
				dataInterface
					.gclVideoLiveInfoExposition(params)
					.then((res) => {
						if (!res) return;
						let tempArr = res.list.splice(0, 4) || []
						this.monArr = tempArr;

						this.$nextTick(() => {
							this.monArr.forEach((v, i) => {
								if (v.url) {
									const playerDom = this.$refs[`my-player${i}`][0]
									Object.assign(this.videoObj, {
										[`video${i}`]: videojs(
											playerDom, {
												autoplay: true, // 设置自动播放
												controls: true, // 显示播放的控件
												preload: 'metadata',
												aspectRatio: '16:9',
												fluid: true,
												sources: [
													// 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
													{
														src: playerDom.attributes
															.playUrl
															.nodeValue,
														type: 'application/x-mpegURL', // 告诉videojs,这是一个hls流
													},
												],
											},
											function() {},
										),
									})

								}
							})
						})
					})
					.catch((err) => {
						console.log(err);
					});
			},
			getRtmp() {
				dataInterface
					.gclVideoLiveRtmp({
						deviceCode: '33010610021322000005',
						mediaType: 1,
						proto: 2
					})
					.then((res) => {
						console.log(res)
					})
			},

			// 清除videojs
			clearVideojs(videoObj = {}) {
				let videoObjTemp = Object.keys(videoObj);
				if (videoObjTemp && videoObjTemp.length) {
					videoObjTemp.forEach(v => {
						if (videoObj[v]) {
							videoObj[v].dispose()
						}
					})
				}
			},
			//初始化视频
			initVideo1(stationId) {
				this.monArr1 = []
				this.videoObj1 = {}

				var params = {
					stationId: stationId,
					pageSize: this.listQuery.pageSize,
					currentPage: this.listQuery.currentPage
				}
				dataInterface
					.gclVideoLiveInfoExposition(params)
					.then((res) => {
						if (!res) return;
						let tempArr = res.list.splice(0, 4) || []
						this.monArr1 = tempArr;

						this.$nextTick(() => {
							this.monArr1.forEach((v, i) => {
								if (v.url) {
									const playerDom1 = this.$refs[`my-player1${i}`][0]

									Object.assign(this.videoObj1, {
										[`video1${i}`]: videojs(
											playerDom1, {
												autoplay: true, // 设置自动播放
												controls: true, // 显示播放的控件
												preload: 'metadata',
												aspectRatio: '16:9',
												fluid: true,
												sources: [
													// 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
													{
														src: playerDom1.attributes
															.playUrl
															.nodeValue,
														type: 'application/x-mpegURL', // 告诉videojs,这是一个hls流
													},
												],
											},
											function() {},
										),
									})
								}
							})
						})
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 点击更改云平台echarts
			changeEcharts(index, name) {

				if (this.inturnShow == 7) {
					return
				}

				clearInterval(this.timer)

				if (this.echartIndex == index) {
					this.show6 = false
					this.echartIndex = 0
				} else {
					this.echartIndex = index
					this.echartTitle = name
					this.show6 = true
				}
			},
			// 获取光伏场景
			guangfucahngjingjiaohu() {
				let params = {}
				params = {
					'enCode': 'snec-mid-gf',
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0',
					"@pile_id": localStorage.getItem("pile_id")
				}
				dataInterface.dataFaceApi(params).then((res) => {
						if (!res) return
						this.guangfucahngjingjiaohuData = res[0] || {}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取充电桩场景
			chongdianzhuangchangjing() {
				let params = {}
				params = {
					'enCode': 'snec-mid-charge-state-elect-curr-actual',
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0',
					"@pile_id": localStorage.getItem("pile_id")
				}
				dataInterface.dataFaceApi(params).then((res) => {
						if (!res) return
						if (res.length > 0) {
							this.chongdianzhuangData = res[res.length - 1] || {}
						} else {
							this.chongdianzhuangData = {}
						}

					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 获取储能场景
			chunengchangjing() {
				let params = {}
				params = {
					'enCode': 'snec-mid-cn',
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0'
				}
				dataInterface.dataFaceApi(params).then((res) => {
						if (!res) return
						this.chunengchangjingData = res[0] || {}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			getHDZ() {
				let params = {
					'enCode': 'snec-mid-exchange-pass',
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0',
					'@station_id': localStorage.getItem("stationSite_id")
				}
				dataInterface.dataFaceApi(params).then((res) => {
						if (!res) return


						this.chenyongchehuandianData = res[0] || {}
						if (localStorage.getItem('chenyongchehuandianDatastatus') == '换电中') {
							if (this.chenyongchehuandianData.status == '空闲') {
								this.chengyongcheOut = true
							} else {
								this.chengyongcheOut = false
							}
						} else {
							this.chengyongcheOut = false
						}
						localStorage.setItem('chenyongchehuandianDatastatus', this.chenyongchehuandianData.status)
					})
					.catch((err) => {
						console.log(err);
					});
			},
			getSYHDZ() {
				let params = {
					'enCode': 'snec-mid-exchange-busi',
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0',
					'@station_id': localStorage.getItem("stationSite_id_shangyong")
				}
				dataInterface.dataFaceApi(params).then((res) => {
						if (!res) return
						this.shangyongchehuandianData = res[0] || {}

						if (localStorage.getItem('shangyongchehuandianDatastatus') == '换电中') {
							if (this.shangyongchehuandianData.status == '空闲') {
								this.shangyongcheOut = true
							} else {
								this.shangyongcheOut = false
							}
						} else {
							this.shangyongcheOut = false
						}
						localStorage.setItem('shangyongchehuandianDatastatus', this.shangyongchehuandianData.status)
					})
					.catch((err) => {
						console.log(err);
					});
			},
		},
		mounted() {
			localStorage.setItem("isV2g", "isV2g")
			this.showVideo()
			this.getRtmp()
			// 服务机器人
			this.noticeTimer = setInterval(() => {
				console.log(this.$refs.positionrobot.offsetTop,this.$refs.positionrobot.offsetLeft, 'this.$refs.positionrobot.offsetLeft')
				if (this.$refs.positionrobot.offsetTop == 1035 && this.$refs.positionrobot.offsetLeft == 1590) {
					this.shownotice = true
					if (!this.showChargeImgTime) {
						this.showChargeImg = true
					}
					this.showChargeImgTime++
					setTimeout(() => {
						this.showChargeImg = false
					}, 10000)
				} else {
					this.showChargeImgTime = 0
					this.shownotice = false
					this.showChargeImg = false
				}
			}, 100)

			let timeNow = new Date().getHours(); //取得当前时间的小时
			//判断当前时间是否在  09:00-12:00 13:00-16:00 之间
			if (timeNow >= 9 && timeNow < 12 || timeNow >= 16 && timeNow < 23) {
				localStorage.setItem("display_state", '放电中')
				this.display_state = '放电中'
			} else {
				localStorage.setItem("display_state", '充电中')
				this.display_state = '充电中'
			}

			this.startInter()
			this.guangfucahngjingjiaohu()
			this.chongdianzhuangchangjing()
			this.chunengchangjing()
			this.getHDZ()
			this.getSYHDZ()
			this.getPileList()
			this.showandhide()
			this.initVideo(localStorage.getItem("stationSite_id"))
			this.initVideo1(localStorage.getItem("stationSite_id_shangyong"))

			// this.initVideo('d13e09fd3c89459789d3933008d4bcfa')
			// this.initVideo1('d13e09fd3c89459789d3933008d4bcfa')
			this.$bus.$on('offCloseFunction', () => {
				this.getHDZ()
				this.getSYHDZ()
				this.chongdianzhuangchangjing()
				this.guangfucahngjingjiaohu()
				this.chunengchangjing()
				this.chunengchangjing()
				this.getPileList()
				this.initVideo(localStorage.getItem("stationSite_id"))
				this.initVideo1(localStorage.getItem("stationSite_id_shangyong"))

				// this.initVideo('d13e09fd3c89459789d3933008d4bcfa')
				// this.initVideo1('d13e09fd3c89459789d3933008d4bcfa')
			})
			let that = this
			setTimeout(() => {
				that.robotAnimation = true
				that.robatURL = require('@/assets/images/exposition/humanrobat-1.png')
				that.robatMP3 = require('@/assets/images/mp3/a.mp3')
				console.log('this.robatMP3', that.robatMP3)
				setTimeout(() => {
					let myAudio = document.querySelector('#humanRobatAudio')
					myAudio.onended = () => {
						that.textPopShow = false
						that.robatURL = require('@/assets/images/exposition/humanrobat.png')
						// 显示ppt
						that.pptPopShow = true
						setTimeout(() => {
							that.pptPopShow = false
						}, 10000 * that.robatPPT.length)
					}
					that.textPopShow = true
				}, 10)
				setTimeout(() => {
					that.textInterval = setInterval(() => {
						that.textTop += -7
						if (that.textTop <= -710) {
							clearInterval(that.textInterval)
						}
					}, 1000)
				}, 10000)
			}, 8000)

			// var audio = new Audio(require('@/assets/images/mp3/a.mp3'))
			// audio.play()
		},
		// 销毁定时器
		beforeDestroy() {
			clearInterval(this.timer)
			clearInterval(this.timer1)
			clearInterval(this.noticeTimer)
			clearInterval(this.textInterval)
			this.timer = false
			debugger
			this.clearVideojs(this.videoObj)
			this.clearVideojs(this.videoObj1)
		}
	}
</script>

<style lang="scss" scope>
	@import './index.scss';
	
</style>
<style>
	.vjs-loading-spinner{
		opacity: 0;
	}
</style>