<template>
	<div>
		<MyTitle title="智能分时充电" type="2"></MyTitle>
		<div style="width:560px;height:325px;margin-top: 30px">
			<MyEcharts :visible="showcdata" :id="'zhinengfenshichongdian'" :options="cdata" />
		</div>
	</div>
</template>

<script>
	import MyEcharts from '@/views/components/charts/echarts.vue'
	import MyTitle from "@/views/components/MyTitle.vue"
	import * as echarts from 'echarts'
	import {
		dataInterface
	} from '@/api/dataInterfaceApi';
	export default {
		components: {
			MyEcharts,
			MyTitle
		},
		data() {
			return {
				showcdata: false,
				// title:"车辆新增及活跃趋势",
				cdata: {
					title: {
						text: '块'
					},
					grid: {
						top: 0,
						left: 0,
						right: 0,
						bottom: 0
					},
					backgroundColor: 'rgba(0, 0, 0, 0.1)',
					tooltip: {
						trigger: 'axis',
						show: true,
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985',
							}
						}
					},
					legend: {
						right: "3%",
						icon: 'roundRect',
						itemHeight: 8, // 修改icon图形大小      
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						type: 'value',
						splitLine: false
					},
					series: [{
						name: '执行前电池数',
						tooltip: {
							valueFormatter: function(value) {
								return value + ' 个';
							}
						},
						type: 'line',
						smooth: true,
						data: [],
						lineStyle: {
							width: 2,
							color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0.1,
									color: '#82EEF2'
								},
							])
						},
						areaStyle: {
							opacity: 0.8,
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0.1,
									color: '#82EEF2'
								},
								{
									offset: 1,
									color: 'rgba(130, 238, 242,0)'
								}
							])
						},
					}, {
						name: '执行后电池数',
						type: 'line',
						smooth: true,
						tooltip: {
							valueFormatter: function(value) {
								return value + ' 个';
							}
						},
						lineStyle: {
							width: 2,
							color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0.1,
									color: '#4597F7'
								},
							])
						},
						areaStyle: {
							opacity: 0.8,
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0.1,
									color: '#4597F7'
								},
								{
									offset: 1,
									color: 'rgba(69, 151, 247,0)'
								}
							])
						},
					}]
				}
			};
		},
		destroyed() {
			this.$bus.$off('offCloseFunction')
		},
		mounted() {
			this.$bus.$on('offCloseFunction', () => {
				this.getBattery24hours()
			})
		},
		methods: {
			//获取24小时电次数分布
			getBattery24hours() {
				this.showcdata = false
				let params = {
					"enCode": "snec-mid-battery-num",
					"@harbour_id": localStorage.getItem("harbour_id") ||
						"76df7579ccc24d2693f6488c38a3a0b0",
					"@station_type": '乘用车'
				}
				dataInterface.dataFaceApi(params).then((res) => {
					if (res) {
						let data = []
						let data1 = []
						let xAxis = []
						res.forEach(item => {
							xAxis.push(item.cur_hour)
							data.push(item.origin_battery_num)
							data1.push(item.later_battery_num)
						})
						this.cdata.xAxis.data = xAxis
						this.cdata.series[0].data = data
						this.cdata.series[1].data = data1
						this.showcdata = true
					}
				}).catch((err) => {
					console.log(err);
				});
			}
		}

	}
</script>

<style lang="scss" scope>
	.main-box-diangganggaikuang {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.main-box-cell {
			margin-bottom: 6px;
			display: flex;
			width: 200px;
			height: 100px;
			padding: 0 10px;

			.main-box-left {
				img {
					width: 80px;
					height: 80px;
				}
			}

			.main-box-right {
				padding-left: 15px;

				.top {
					height: 50px;
					line-height: 70px;
				}

				.bottom {
					height: 50px;
					font-size: 22px;
					line-height: 30px;
				}
			}
		}
	}
</style>