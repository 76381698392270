<template>
	<div class="exposition-left-main">
		<div class="exposition-left-main-cell">
			<zhinengfenliu></zhinengfenliu>
			<!-- <zhinengfenshichongdian></zhinengfenshichongdian> -->
		</div>
		<div class="exposition-left-main-cell">
				<cheliangzhinengdiaodu1></cheliangzhinengdiaodu1>
			<!-- <zhinengfenshichongdian1></zhinengfenshichongdian1> -->
		</div>
		<div class="exposition-left-main-cell">
			<cheliangzhinengdiaodu></cheliangzhinengdiaodu>
		</div>
	</div>
</template>

<script>
	import zhinengfenliu from '@/views/components/exposition/zhinengfenliu.vue'
	// import zhinengfenshichongdian from '@/views/components/exposition/zhinengfenshichongdian.vue'
	import cheliangzhinengdiaodu1 from '@/views/components/exposition/cheliangzhinengdiaodu1.vue'
	import cheliangzhinengdiaodu from '@/views/components/exposition/cheliangzhinengdiaodu.vue'
	export default{
		components: {
			zhinengfenliu,
			// zhinengfenshichongdian,
			cheliangzhinengdiaodu1,
			cheliangzhinengdiaodu
		}
	}
</script>

<style lang="scss" scope>
	.exposition-left-main {
		width: 650px;
		display: flex;
		padding-left: 50px;
		flex-wrap: wrap;
		.exposition-left-main-cell{
			width: 650px;
			height: 320px;
			margin-right: 20px;
		}
	}
</style>