<template>
	<div class="screen-wrapper122">
		<div style="height:80px;width:100%" v-if="fromUser == 'guizhou'"></div>
		<div class="screen screenBg" id="screen">
			<div class="demo-root">
				<header>
					<expositionHeader></expositionHeader>
				</header>
				<main>
					<div class="demo-left">
						<indexleft></indexleft>
					</div>
					<div class="demo-center">
						<indexcenter></indexcenter>
					</div>
					<div class="demo-right">
						<indexright></indexright>
					</div>
				</main>
			</div>
			<img v-if="isReading" class="shuziren" src="@/assets/images/exposition/humanrobat-1.png" alt="">
		</div>
	</div>
</template>

<script>
	import expositionHeader from '@/views/exposition/components/expositionHeader/expositionHeader.vue'
	import indexleft from '@/views/exposition/components/indexleft/index.vue'
	import indexright from '@/views/exposition/components/indexright/index.vue'
	import indexcenter from '@/views/exposition/components/indexcenter/index.vue'
	export default {
		components: {
			indexleft,
			expositionHeader,
			indexright,
			indexcenter
		},
		data() {
			return {
				pageInterval: null,
				intervalNum: 180,
				intervalNumSet: 180,
				fromUser: '',
				isReading: false
			}
		},
		beforeCreate() {
			console.log('this.$route====333=======', this.$route.query.fromScreen)
			if(this.$route.query.fromScreen) {
				sessionStorage.setItem('fromScreen', this.$route.query.fromScreen)
			}
			if(this.$route.query.fromScreen) {
				sessionStorage.setItem('fromScreen', this.$route.query.fromScreen)
			}
			localStorage.setItem('harbour_id', '76df7579ccc24d2693f6488c38a3a0b0')
			let fromPath = localStorage.getItem('newBigScreen::fromPath')
			let playCount = parseInt(localStorage.getItem('newBigScreen::playCount') || 0, 10)
			if (fromPath && ++playCount >= 2) {
				localStorage.setItem('newBigScreen::playCount', '0')
				localStorage.setItem('newBigScreen::fromPath', '')
				location.reload()
			} else {
				localStorage.setItem('newBigScreen::playCount', playCount + '')
			}
		},
		mounted() {
			if (sessionStorage.getItem('fromScreen') == 2 && this.$route.path !== '/chonghuan-monitor') {
				this.$bus.$on('readingFn', (data) => {
					this.isReading = data.isReading
				})
			}
			document.title = '铁塔数字能源光储充换车算云'
			let {
				fromUser,
				scaleY
			} = this.$route.query
			fromUser ? sessionStorage.setItem('fromUser', fromUser) : ''
			scaleY ? localStorage.setItem('newBigScreen::scaleY', scaleY) : ''

			let that = this
			this.fromUser = sessionStorage.getItem('fromUser')
			// document.title = '移动能源光储充换车云一体化大屏'
			//初始化自适应  ----在刚显示的时候就开始适配一次
				that.handleScreenAuto();
			
			//绑定自适应函数   ---防止浏览器栏变化后不再适配
			window.onresize = (function() {
				that.handleScreenAuto()
			});

		},
		deleted() {
			window.onresize = null;
		},
		methods: {
			//数据大屏自适应函数
			handleScreenAuto() {
				const designDraftWidth = 3840; //设计稿的宽度
				const designDraftHeight = 1590; //设计稿的高度
				//根据屏幕的变化适配的比例
				const scale = document.documentElement.clientWidth / document.documentElement.clientHeight <
					designDraftWidth / designDraftHeight ?
					(document.documentElement.clientWidth / designDraftWidth) :
					(document.documentElement.clientHeight / designDraftHeight);
				//缩放比例

				console.log('777777777777777777777777777777777777777666666666666')
				console.log(scale)

				document.querySelector('#screen').style.transform = `scale(${scale+0.005}) translate(-50%)`;
				let scaleY = parseFloat(localStorage.getItem('newBigScreen::scaleY')) || 1
				if (sessionStorage.getItem('fromUser') == 'guizhou') {
					document.querySelector('#screen').style.transform = `scale(${scale+0.005}, ${scale * 1.6}) translate(-50%)`;
				} else {
					document.querySelector('#screen').style.transform =
						`scale(${scale+0.005}, ${scale * scaleY}) translate(-50%)`;
				}
			}
		},
		destroyed() {
			clearInterval(this.pageInterval)
			this.$bus.$off('readingFn')
		}
	}
</script>

<style lang="scss" scope>
	@import '@/assets/scss/pc.scss';
	@import '@/assets/scss/main-map.scss';

	@font-face {
		font-family: 'DS-Digital-Bold';
		src: url('~@/assets/font/DS-Digital-Bold.ttf');
	}

	.screen-wrapper122 {
		overflow: hidden;
		height: 100%;
		width: 100%;
		font-size: 20px;
		color: #ffffff;
		.DS-Digital-Bold {
			font-family: "DS-Digital-Bold";
		}

		.screenBg {
			background-image: url("~@/assets/images/exposition/senceBg.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}

		.screen {
			overflow: hidden;
			display: inline-block;
			width: 3840px;
			height: 1590px;
			transform-origin: 0 0;
			position: absolute;
			left: 50%;
			.shuziren{
				position: absolute;
				right: 780px;
				bottom: 0;
				z-index: 15;
			}
			.demo-root {
				header {
					width: 3840px;
					height: 150px;
					text-align: center;
				}

				main {
					height: 1500px;
					display: flex;
					.demo-left {
						width: 650px;
						height: 1460px;
						display: flex;
					}
					.demo-center {
						// width: 1940px;
						margin-top: 20px;
						flex: 1;
					}

					.demo-right {
						width: 650px;
						display: flex;
						height: 1460px;
					}
				}

				footer {
					width: 100%;
					height: 100px;
					font-size: 40px;
					text-align: center;
					line-height: 100px;
					background: rgba(19, 211, 115, 0.3);
				}
			}
		}
	}
</style>