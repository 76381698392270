<template>
	<div>
		<div style="width: 516px; height: 205px">
			<MyEcharts :visible="showcdata" :id="'huandianzhanchart1'" :options="cdata" />
		</div>
	</div>
</template>

<script>
	import MyEcharts from "@/views/components/charts/echarts.vue";
	import * as echarts from "echarts";
	import {
		dataInterface
	} from "@/api/dataInterfaceApi";
	export default {
		components: {
			MyEcharts,
		},
		data() {
			return {
				showcdata: false,
				// title:"车辆新增及活跃趋势",
				cdata: {
					title: {
						text: '个'
					},
					backgroundColor: "rgba(0, 0, 0, 0.1)",
					tooltip: {
						trigger: "axis",
						show: true,
						axisPointer: {
							type: "cross",
							label: {
								//   backgroundColor: "#6a7985",
							},
						},
					},
					legend: {
						right: "20%",
						icon: "roundRect",
						itemHeight: 8, // 修改icon图形大小
					},
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: [],
					},
					yAxis: [{
						type: "value",
						//   splitLine: false,
					}, {
						name: 'kWh',
						type: "value",
						//   splitLine: false,
					}],
					series: [{
							name: "排队数",
							type: "bar",
							smooth: true,
							data: [],
							tooltip: {
								valueFormatter: function(value) {
									return value + ' 位';
								}
							},
							lineStyle: {
								width: 2,
								color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
										offset: 0.1,
										color: "#0EAAD3",
									},
									{
										offset: 0.9,
										color: "#0EAAD3",
									},
								]),
							},
							itemStyle: {
								color: "#0EAAD3"
							},
						},
						{
							name: "可换电池数",
							type: "bar",
							smooth: true,
							data: [],
							tooltip: {
								valueFormatter: function(value) {
									return value + ' 个';
								}
							},
							lineStyle: {
								width: 2,
								color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
										offset: 0.1,
										color: "#18EAC2",
									},
									{
										offset: 0.9,
										color: "#17464A",
									},
								]),
							},
							itemStyle: {
								color: "#18EAC2"
							},
						},
						{
							// color: '#FFD15C',
							name: "充入电量",
							type: "line",
							smooth: true,
							yAxisIndex: 1,
							tooltip: {
								valueFormatter: function(value) {
									return value + ' kWh';
								}
							},
							data: [],
							itemStyle: {
								color: "#FFD15C"
							},
							lineStyle: {
								width: 2,
								color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
										offset: 0.1,
										color: "#FFD15C",
									},
									{
										offset: 0.9,
										color: "#FFD15C",
									},
								]),
							},
						},
					],
				},
			};
		},
		destroyed() {
			// this.$bus.$off('offCloseFunction')
		},
		mounted() {
			// this.$bus.$on("offCloseFunction", () => {
			//   this.getBattery24hours();
			// });
			this.getBattery24hours();
		},
		methods: {
			//获取24小时电次数分布
			getBattery24hours() {
				this.showcdata = false;
				let params = {
					enCode: "snec-mid-queue-battery-elect",
					"@harbour_id": localStorage.getItem("harbour_id") ||
						"76df7579ccc24d2693f6488c38a3a0b0",
					"@station_type": "乘用车",
				};
				dataInterface
					.dataFaceApi(params)
					.then((res) => {
						if (res) {
							console.log(res, "换电站趋势图");
							let data = [];
							let data2 = [];
							let data3 = [];
							let xAxis = [];
							res.forEach((item) => {
								xAxis.push(item.cur_hour);
								data.push(item.queue_num);
								data2.push(item.can_change_battery_num);
								data3.push(item.elect);
							});
							this.cdata.xAxis.data = xAxis;
							this.cdata.series[0].data = data;
							this.cdata.series[1].data = data2;
							this.cdata.series[2].data = data3;
							this.showcdata = true;
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
		},
	};
</script>

<style lang="scss" scope>
	.main-box-diangganggaikuang {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.main-box-cell {
			margin-bottom: 6px;
			display: flex;
			width: 200px;
			height: 100px;
			padding: 0 10px;

			.main-box-left {
				img {
					width: 80px;
					height: 80px;
				}
			}

			.main-box-right {
				padding-left: 15px;

				.top {
					height: 50px;
					line-height: 70px;
				}

				.bottom {
					height: 50px;
					font-size: 22px;
					line-height: 30px;
				}
			}
		}
	}
</style>