<template>
	<div class="target flex justify-between">
		<div class="postion" v-for="(item,index) in targetList" :key="index">
			<div style="flex: 1;" class="back">
				
				<div class="counts flex">
					{{item.counts}}
					<!-- <dv-digital-flop class="num" style="height: 40px" :style="{width: index == 3 ? '50px' : '120px' }"
						:config="dvDigitalFlopConfig(item)" /> -->
					<!-- <div class="item_text flex1">
						<span>&nbsp;&nbsp;{{ item.unit }}</span>
					</div> -->
				</div>
				<p class="text">{{ item.text }}<span>&nbsp;({{ item.unit }})</span></p>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		dataInterface
	} from '@/api/dataInterfaceApi'
	import vehicleAll from '@/assets/images/comm/vehicleAll1.png'
	import carbonEmissionsAll from '@/assets/images/comm/carbonEmissionsAll1.png'
	import mileageAll from '@/assets/images/comm/mileageAll1.png'
	import electricityAll from '@/assets/images/comm/electricityAll1.png'
	export default {
		data() {
			return {
				timer: '',
				targetList: [{
						counts: '11236',
						text: "用电量累计",
						icon: vehicleAll,
						width: '90px',
						unit: 'kWh'
					},
					{
						counts: '3340',
						text: "光伏发电量",
						icon: carbonEmissionsAll,
						width: '90px',
						unit: 'kWh'
					},
					{
						counts: '7780',
						text: "二氧化碳减排",
						icon: mileageAll,
						height: '70px',
						unit: 'kg'
					},
					{
						counts: '44660',
						text: "光伏发电占比",
						icon: electricityAll,
						height: '70px',
						unit: '%'
					}
				],
			};
		},
		components: {},
		props: {},
		watch: {},
		destroyed() {
			// this.$bus.$off('onfangdiangonglv')
			clearInterval(this.timer)
		},
		mounted() {
			this.timer = setInterval(() => {
				this.getData()
			}, 50000)
			this.$bus.$on('onfangdiangonglv', () => {
				this.getData()
			})
			this.getData()
		},
		methods: {
			dvDigitalFlopConfig(item = {}) {
				let counts = parseFloat(item.counts || 0)
				// let cWidth = document.documentElement.clientWidth
				let config = {
					number: [parseFloat(counts)],
					textAlign: item.textAlign || 'left',
					// content: '{nt}' + item.unit,
					style: {
						fill: item.color || '#ffc167',
						fontFamily: 'DS-Digital-Bold',
					}
				}
				return config
			},
			// 初始化
			getData(data) {
				// 用电量累计
				this.yongdianliangleiji(data)
				// 光伏发电量
				this.gaungfufadianliang(data)
				// 二氧化碳减排
				this.eryanghuatanjianpai(data)
				// 光伏发电占比
				this.guangfufadianzhanbi(data)
			},
			// 用电量累计
			yongdianliangleiji() {
				let params = {}
				params = {
					'enCode': 'snec-mid-total_elect',
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0'
				}
				dataInterface.dataFaceApi(params).then((res) => {
						if (!res) return
						let data = res && res[0] || {}
						if (data.total_elect) {
							this.targetList[0].counts = parseInt(data.total_elect, 10)
						} else {
							this.targetList[0].counts = '-'
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 光伏发电量
			gaungfufadianliang() {
				let params = {}
				params = {
					'enCode': 'snec-mid-gf_elect',
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0'
				}
				dataInterface.dataFaceApi(params).then((res) => {
						if (!res) return
						let data = res && res[0] || {}
						if (data.gf_elect) {
							this.targetList[1].counts = parseInt(data.gf_elect, 10)
						} else {
							this.targetList[1].counts = '-'
						}

					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 二氧化碳减排
			eryanghuatanjianpai() {
				let params = {}
				params = {
					'enCode': 'snec-mid-carbon_emission',
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0'
				}
				dataInterface.dataFaceApi(params).then((res) => {
						if (!res) return
						let data = res && res[0] || {}
						if (data.carbon_emission) {
							this.targetList[2].counts = parseInt(data.carbon_emission, 10)
						} else {
							this.targetList[2].counts = '-'
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 光伏发电占比
			guangfufadianzhanbi() {
				let params = {}
				params = {
					'enCode': 'snec-mid-gf_elect_rate',
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0'
				}
				dataInterface.dataFaceApi(params).then((res) => {
						if (!res) return
						let data = res && res[0] || {}
						if (data.gf_elect_rate) {
							this.targetList[3].counts = data.gf_elect_rate
						} else {
							this.targetList[3].counts = '-'
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
		}
	}
</script>
<style lang="scss" scoped>
	@import '@/assets/scss/comm.scss';
	@font-face {
		font-family: 'DS-Digital-Bold';
		src: url('~@/assets/font/DS-Digital-Bold.ttf');
	}
	.DS-Digital-Bold {
		font-family: "DS-Digital-Bold";
	}
	// .back{
	//   background-size:100% 100%;
	// }
	.postion {
		padding: 8px 5px;
		height: 220px;
		width: 380px;
		background-color: yellow;
	}

	.justify-between {
		justify-content: space-between;
	}

	.postion:nth-child(1) {
		background: url('~@/assets/images/comm/vehicleAll1.png') no-repeat;
		// background: url('~@/assets/images/index/co1.png') no-repeat;
		background-size: 100% 100%;
		margin-left: 100px;
	}

	.postion:nth-child(2) {
		background: url('~@/assets/images/comm/carbonEmissionsAll1.png') no-repeat;
		// background: url('~@/assets/images/index/co2.png') no-repeat;
		background-size: 100% 100%;
		margin-left: -350px;
	}
	.postion:nth-child(3) {
		background: url('~@/assets/images/comm/mileageAll1.png') no-repeat;
		// background: url('~@/assets/images/index/co3.png') no-repeat;
		background-size: 100% 100%;
		margin-right: -350px;
	}

	.postion:nth-child(4) {
		background: url('~@/assets/images/comm/electricityAll1.png') no-repeat;
		// background: url('~@/assets/images/index/co4.png') no-repeat;
		background-size: 100% 100%;
		margin-right: 100px;
	}

	.target {
		width: 100%;
		height: 122px;
		display: flex;
		position: absolute;
		top: 30px;
		margin-top: 20px;

		// margin-bottom:30px;
		.postion {
			// margin: 0 20px;
			// flex: 1;
			width: 380px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-right: 20px;

			// background: linear-gradient(180deg, rgba(47, 164, 159, 0.11) 0%, rgba(1, 177, 169, 0.06) 79%, rgba(53, 149, 147, 0.11) 100%);
			.pos-line {
				width: 6px;
				height: 62px;
			}

			.pos-logo {
				width: 32px;
				height: 32px;
				margin: 0 30px 0 15px;
			}
		}

		.icon {
			width: 100px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.counts {
			font-size: 50px;
			margin-left: 100px;
			display: flex;
			flex-direction: row;
			align-items: center;
			color: #FFC166;
			height: 40px;
			@include ffamily-HT;
			.item_text {
				font-size: 30px;
				margin-top: 7px;
				color: #fff;
			}

			.DS-Digital-Bold {
				font-family: "DS-Digital-Bold";
			}
		}

		.text {
			margin-left: 100px;
			font-size: 32px;
			height:45px;
			line-height: 60px;
			white-space: nowrap;
		}
	}
</style>