<template>
	<div class="exposition-right-main">
		<div class="exposition-right-main-right">
			<zhinengfenshichongdian></zhinengfenshichongdian>
			<!-- <zhinengfenliu></zhinengfenliu> -->
		</div>
		<div class="exposition-right-main-right">
			<zhinengyunwei></zhinengyunwei>
		</div>
		<div class="exposition-right-main-right">
			<zhinengdiaodu></zhinengdiaodu>
		</div>
	</div>
</template>

<script>
import zhinengfenshichongdian from '@/views/components/exposition/zhinengfenshichongdian.vue'
	// import zhinengfenliu from '@/views/components/exposition/zhinengfenliu.vue'
	import zhinengyunwei from '@/views/components/exposition/zhinengyunwei.vue'
	import zhinengdiaodu from '@/views/components/exposition/zhinengdiaodu.vue'
	export default {
		components: {
			zhinengfenshichongdian,
			zhinengyunwei,
			zhinengdiaodu
		}
	}
</script>

<style lang="scss" scope>
	.exposition-right-main {
		width: 650px;
		display: flex;
		flex-wrap: wrap;
		padding-left: 40px;
		.exposition-right-main-right {
			width: 650px;
			height: 320px;
			margin-right: 20px;
		}
	}
</style>