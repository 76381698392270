<template>
	<div>
		<div style="width:526px;height:180px">
			<MyEcharts :visible="showcdata" :id="'chongdianzhuang121'" :options="cdata" />
		</div>


	</div>
</template>

<script>
	import MyEcharts from '@/views/components/charts/echarts.vue'
	import * as echarts from 'echarts'
	import {
		dataInterface
	} from '@/api/dataInterfaceApi';
	export default {
		components: {
			MyEcharts
		},
		data() {
			return {
				showcdata: false,
				// title:"车辆新增及活跃趋势",
				cdata: {
					title: {
						text: 'A'
					},
					grid: {
						top: 0,
						left: 0,
						right: 0,
						bottom: 0
					},
					tooltip: {
						trigger: 'axis',
						show: true,
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985',
							}
						}
					},

					legend: {
						right: "16%",
						icon: 'roundRect',
						itemHeight: 8, // 修改icon图形大小      
					},
					backgroundColor: 'rgba(0, 0, 0, 0.1)',
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						type: 'value',
						splitLine: false
					},
					series: [{
						name: '实时电流',
						color: '#FECE52',
						tooltip: {
							valueFormatter: function(value) {
								return value + ' A';
							}
						},
						type: 'line',
						smooth: true,
						data: [],
						lineStyle: {
							width: 2,
							color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0,
									color: '#FECE52'
								},
								{
									offset: 1,
									color: '#FECE52'
								}
							])
						},
					}]
				}
			};
		},
		destroyed() {
			// this.$bus.$off('offCloseFunction')
		},
		mounted() {
			this.$bus.$on('offCloseChongdianFunction', () => {
				this.getBattery24hours()
			})
			this.getBattery24hours()
		},
		methods: {
			//获取24小时电次数分布
			getBattery24hours() {
				this.showcdata = false


				let isV2g = localStorage.getItem("isV2g")
				console.log(isV2g, localStorage.getItem("display_state"), 'isV2')
				if (isV2g == 'isV2g') {
					let params = {
						"enCode": "snec-mid-charge-curr",
						"@harbour_id": localStorage.getItem("harbour_id") ||
							'2fa983b015f1434b99d08c5de9f289aa5b1b5100e6bc4560a3d07efb1bb3ce8b',
						"@display_state": localStorage.getItem("display_state")
					}
					console.log(localStorage.getItem("display_state"), 'localStorage.getItem("display_state")')
					dataInterface.dataFaceApi(params).then((res) => {
						if (res) {
							let data = []
							let xAxis = []
							res.forEach(item => {
								xAxis.push(item.cur_time)
								data.push(item.curr)
							})
							this.cdata.xAxis.data = xAxis
							this.cdata.series[0].data = data
							this.showcdata = true
						}
					}).catch((err) => {
						console.log(err);
					});
				} else {
					this.showcdata = false
					let params = {
						"enCode": "snec-mid-charge-state-elect-curr-actual",
						"@harbour_id": localStorage.getItem("stationSite_id") ||
							'2fa983b015f1434b99d08c5de9f289aa5b1b5100e6bc4560a3d07efb1bb3ce8b',
						"@pile_id": localStorage.getItem("pile_id")
					}
					console.log(localStorage.getItem("display_state"), 'snec-mid-charge-charge')
					dataInterface.dataFaceApi(params).then((res) => {
						if (res) {
							let data = []
							let data1 = []
							let xAxis = []
							res.forEach(item => {
								xAxis.push(item.cur_time)
								data.push(item.curr)
								data1.push(item.elect)
							})
							this.cdata.xAxis.data = xAxis
							this.cdata.series[0].data = data
							this.showcdata = true
						}
					}).catch((err) => {
						console.log(err);
					});
				}



			}
		}

	}
</script>

<style lang="scss" scope>
	.main-box-diangganggaikuang {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.main-box-cell {
			margin-bottom: 6px;
			display: flex;
			width: 200px;
			height: 100px;
			padding: 0 10px;

			.main-box-left {
				img {
					width: 80px;
					height: 80px;
				}
			}

			.main-box-right {
				padding-left: 15px;

				.top {
					height: 50px;
					line-height: 70px;
				}

				.bottom {
					height: 50px;
					font-size: 22px;
					line-height: 30px;
				}
			}
		}
	}
</style>